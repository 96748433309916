import React from 'react'
import { useNavigate } from 'react-router-dom'

const HeroSection = () => {
    const navigate = useNavigate();
  return (
    <div>      
    <section className="text-center max-w-5xl mt-12">
    <h1 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-indigo-600 to-purple-600">
    Showcase your any ideas, get feedback, and find the right people to bring it to life.
    </h1>
    <p className="mt-6 text-lg text-gray-700 font-medium max-w-3xl mx-auto">
      datatreya is a <span className="text-indigo-500 font-semibold">hub of innovation</span>, connecting <span className="text-green-500 font-semibold">visionaries and investors</span> to shape the future.
    </p>
    <button onClick={() => navigate('/signup')} className="mt-6 px-8 py-4 bg-gradient-to-r from-blue-500 to-indigo-500 text-white text-lg font-semibold rounded-full shadow-lg hover:scale-105 transition duration-300">
      Get Started for Free
    </button>
  </section></div>
  )
}

export default HeroSection;